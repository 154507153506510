
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import AppProductCard from '@/components/general/AppProductCard'
import AppCardShowMore from '@/components/general/AppCardShowMore'
import 'swiper/swiper.scss'

export default {
  name: 'AppSetsSection',
  components: {
    AppProductCard,
    AppCardShowMore,
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    section_link: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      default: () => [],
    },
    show_more: {
      type: Boolean,
      default: true,
    },
    isNoSlider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 16,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          dynamicBullets: true,
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
    currentProducts() {
      return this.$route.fullPath !== '/'
        ? this.products
        : this.products.filter((p) => p.show_on_main === true)
    },
  },
}
