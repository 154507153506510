
export default {
  name: 'AppProductCard',
  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
    isNoSlider: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHover: false,
    }
  },
  computed: {
    icon() {
      const { icon, iconHover } = this.cardData

      if (this.$mq === 'sm' && this.isNoSlider) return icon

      return this.isHover || this.$mq === 'sm' ? iconHover : icon
    },
  },
  methods: {
    hoverHandler() {
      this.isHover = !this.isHover
    },
  },
}
