
import AppPlusButton from '@/components/general/AppPlusButton'

export default {
  name: 'AppCardShowMore',
  components: { AppPlusButton },

  props: {
    cardData: {
      type: Object,
      default: () => ({}),
    },
    section_link: {
      type: String,
      default: '',
    },
  },

  computed: {
    getText() {
      return this.$mq === 'lg'
        ? 'Показать все'
        : 'Показать все наборы реагентов'
    },
  },
}
